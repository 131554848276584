import { inject, Injectable, isDevMode, signal, WritableSignal } from '@angular/core';
import {
  deleteDoc,
  deleteField,
  doc,
  Firestore,
  getDoc,
  onSnapshot,
  serverTimestamp,
  setDoc,
  Timestamp,
  Unsubscribe,
} from '@angular/fire/firestore';
import { AuthService } from '../../services/auth.service';
import { InviteStatus } from '../../services/invite-listener.service';
import { DialogElement, ResponseDialog } from './project-dashboard-chatbot/project-dashboard-chatbot.component';
import { EventBus, EventBusEnum } from '../../services/event-bus.service';
import { marked } from 'marked';

export interface ProjectLevelInviteData {
  [email: string]: {
    role: string;
    status: InviteStatus;
  };
}

export interface RolesData {
  [user: string]: {
    role: string;
    email: string;
    memberSince: Timestamp;
  };
}

export interface ProjectData {
  name: string;
  roles: RolesData;
  invites: ProjectLevelInviteData;
  configuration: RagConfiguration;
  asyncUploadEnd: Timestamp;
}

export interface SessionData {
  role: string;
  content: string;
}

export interface RagConfiguration {
  uploadData: {
    chunkSize: number;
    chunkOverlap: number;
  };
  queryAndPrompt: {
    temperature: number;
    kNearestVectors: number;
    maxTokens: number;
    promptTemplate: string;
    noCommonKnowledgePrompt: boolean;
  };
  embeddingsGenerator: string;
  chatCompletion: string;
  enableSessions: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private db: Firestore = inject(Firestore);
  private authService: AuthService;
  public projectData: ProjectData | undefined;
  private _dialogData: DialogElement[] = [];
  private _expiresAt: Date | null = null;

  private projectDataUnsubscribe: Unsubscribe | undefined;
  private _projectId: string | null | undefined;

  public asyncUploadEnd: WritableSignal<Date | undefined | null> = signal<Date | undefined | null>(null);
  // currently solely used for async uploads. Could probably be extended with not much effort
  public areUploadsRunning = signal(false);

  constructor(
    authService: AuthService,
    private eventBus: EventBus,
  ) {
    this.authService = authService;
  }

  async init(projectId: string | null) {
    if (isDevMode()) {
      console.log(`${AuthService.performanceInSeconds()}s: initializing project with id ${projectId}`);
    }
    this.setAndClearData(projectId);

    await this.fetchProjectDataSync();

    this.eventBus.emitEvent({ type: EventBusEnum.PROJECT_SELECTED });
  }

  private setAndClearData(projectId: string | null) {
    this._projectId = projectId;
    this._dialogData = [];
    this._expiresAt = null;
    this.projectData = undefined;
    this.asyncUploadEnd.set(null);
  }

  get projectId() {
    if (!this._projectId) {
      throw new Error('Projekt-ID nicht verfügbar.');
    }
    return this._projectId;
  }

  async fetchProjectDataSync() {
    if (this.projectDataUnsubscribe) {
      this.projectDataUnsubscribe();
    }

    // necessary so we can assure that project data are instantly available at subpath components
    this.projectData = (await getDoc(this.projectDocRef)).data() as ProjectData;

    await this.fetchDialogData();

    this.projectDataUnsubscribe = onSnapshot(this.projectDocRef, (doc) => {
      this.projectData = doc.data() as ProjectData;
      if (isDevMode()) {
        console.log(`${AuthService.performanceInSeconds()}s: project data loaded`);
      }
    });
  }

  public get projectDocRef() {
    return doc(this.db, `tenants/${this.authService.tenantId}/projects`, this.projectId);
  }

  deleteProjectInvitationEntry(email: string) {
    const data = {
      invites: {
        [email]: deleteField(),
      },
    };
    setDoc(this.projectDocRef, data, { merge: true });
  }

  async registerInvitationAtProject(newUsersEmail: string, role: string) {
    const data = {
      invites: {
        [newUsersEmail]: {
          role: role,
          inviteDate: serverTimestamp(),
          status: InviteStatus.PENDING,
        },
      },
    };
    await setDoc(this.projectDocRef, data, { merge: true });
  }

  removeMember(email: string) {
    const userId = this.findUserByEmail(email);
    if (userId !== null) {
      this.removeMemberFromProjectDocument(userId);
      this.authService.revokeRole(userId, this.projectId);
    } else {
      if (isDevMode()) {
        console.log('kein Benutzer gefunden.');
      }
    }
  }

  findUserByEmail(email: string): string | null {
    for (const userId in this.projectData?.roles) {
      if (this.projectData?.roles[userId].email === email) {
        return userId;
      }
    }
    return null; // Benutzer nicht gefunden
  }

  private removeMemberFromProjectDocument(userId: string) {
    const data = {
      roles: {
        [userId]: deleteField(),
      },
    };
    setDoc(this.projectDocRef, data, { merge: true });
  }

  get dialogData(): DialogElement[] {
    return this._dialogData;
  }

  get expiresAt(): Date | string {
    if (this._expiresAt === null) {
      return 'Keine gültige Session gefunden.';
    } else if (this.isExpired(this._expiresAt)) {
      return 'Session abgelaufen.';
    } else if (this._expiresAt instanceof Date) {
      return this._expiresAt.toLocaleString('de-De', {
        dateStyle: 'full',
        timeStyle: 'short',
      });
    } else {
      return 'Ungültiges Datum.';
    }
  }

  public async updateExpiresAt(): Promise<void> {
    if (this.projectData?.configuration?.enableSessions === false) {
      return;
    }
    const retrievedDoc = await this.retrieveUserSessionDoc();

    if (!retrievedDoc.exists()) {
      if (isDevMode()) {
        console.log('No session data found for this user and project.');
      }
      this._expiresAt = null;
      return;
    }
    const sessionData = retrievedDoc.data();
    this._expiresAt = new Date(this.getCurrentDate(sessionData['expiresAt']));
    if (this.isExpired(this._expiresAt)) {
      if (isDevMode()) {
        console.log('Session expired.');
      }
      return;
    }

    return;
  }

  private async retrieveUserSessionDoc() {
    const userId = this.authService.getUserFromToken()?.uid;
    try {
      const sessionDocRef = doc(
        this.db,
        `tenants/${this.authService.tenantId}/session-data/${userId}/user-sessions/${this.projectId}`,
      );
      return await getDoc(sessionDocRef);
    } catch (error) {
      console.error('Error fetching dialog data:', error);
      throw error;
    }
  }

  private convertSerializedHistoryToQueryAnswerPair(
    decodedMessages: { role: string; content: string }[],
  ): { request: string; response: ResponseDialog }[] {
    const result: { request: string; response: ResponseDialog }[] = [];
    let cachedQuery = null;

    for (const message of decodedMessages) {
      if (message.role === 'human') {
        cachedQuery = message.content;
      } else if (message.role === 'ai' && cachedQuery) {
        const cachedSessionMessage: ResponseDialog = {
          answer: marked(message.content) as string,
          document_ids: [],
          metadata: [],
        };
        result.push({
          request: cachedQuery,
          response: cachedSessionMessage,
        });
        cachedQuery = null;
      }
    }
    return result;
  }

  private deserializeBinaryString(data: any[]): Record<string, any>[] {
    // deserializes utf-8 encoded binary string which is loaded from firebase
    return data.map((item) => {
      const binaryString = item._byteString?.binaryString;
      if (!binaryString) {
        console.warn('Unexpected data structure:', item);
        return {};
      }
      try {
        return JSON.parse(item._byteString.binaryString);
      } catch (error) {
        console.error('Failed to parse binaryString as JSON:', item._byteString.binaryString, error);
        return {};
      }
    });
  }

  private isExpired(date: Date): boolean {
    const milliseconds = date.valueOf();
    return milliseconds <= Date.now();
  }

  private getCurrentDate(timestamp: { seconds: number; nanoseconds: number }): Date {
    // converts a firestore timestamp object which has seconds and nanoseconds since 01.01.1970 to a JavaScript Date Object
    // 1 second has 1000 milliseconds and 1 millisecond has 1 000 000 nanoseconds, thats where the numbers come from
    const milliseconds = timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1_000_000);
    return new Date(milliseconds);
  }

  async fetchDialogData(): Promise<void> {
    if (this.projectData?.configuration?.enableSessions === false) {
      this._dialogData = [];
      this._expiresAt = null;
      return;
    }
    const retrievedDoc = await this.retrieveUserSessionDoc();
    if (!retrievedDoc.exists()) {
      if (isDevMode()) {
        console.log('No session data found for this user and project.');
      }
      this._dialogData = [];
      this._expiresAt = null;
      return;
    }
    const sessionData = retrievedDoc.data();

    this._expiresAt = new Date(this.getCurrentDate(sessionData['expiresAt']));

    if (this.isExpired(this._expiresAt)) {
      if (isDevMode()) {
        console.log('Session expired.');
      }
      this._dialogData = [];
      this._expiresAt = null;
      return;
    }
    const decodedSessionData = this.deserializeBinaryString(sessionData['History']) as SessionData[];
    this._dialogData = this.convertSerializedHistoryToQueryAnswerPair(decodedSessionData);
    return;
  }

  async resetDialogData() {
    if (this.projectData?.configuration?.enableSessions === true) {
      const userId = this.authService.getUserFromToken()?.uid;
      await deleteDoc(
        doc(this.db, `tenants/${this.authService.tenantId}/session-data/${userId}/user-sessions/${this.projectId}`),
      );
    }
    this._dialogData = [];
  }

  addToDialogData(newElement: DialogElement) {
    this._dialogData.push(newElement);
  }

  async persistAsyncUploadEndDate() {
    const data = {
      asyncUploadEnd: Timestamp.fromDate(this.asyncUploadEnd()!),
    };
    await setDoc(this.projectDocRef, data, { merge: true });
  }

  removeAsyncUploadEndDate() {
    const data = {
      asyncUploadEnd: deleteField(),
    };
    setDoc(this.projectDocRef, data, { merge: true });
  }
}
